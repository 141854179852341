import { useEffect, useContext } from 'react';
import { GlobalContext } from '../context/GlobalContext';

const UseGoToSection = () => {
    const context = useContext(GlobalContext); // Access currentClass from context

    useEffect(() => {
      const goToSection = () => {
        // Logic for scrolling or navigating to a section
        
        const header = document.getElementById('header-navbar');
        const headerHeight = header ? header.offsetHeight : 0;

        // Ensure context.state.section is valid and not empty
        if (!context.state.section) {
          console.log('No section specified');
          return;
        }

        const section = document.querySelector('.' + context.state.section); // Using currentClass from context

        if (!section) {
          console.log('Section not found');
          return; // Exit early if section is not found
        }

        const sectionTop = section.getBoundingClientRect().top + window.scrollY;

        // Use a debounce function to prevent multiple rapid scroll events
        let isScrolling;
        window.clearTimeout(isScrolling);
        isScrolling = setTimeout(() => {
          if(sectionTop && sectionTop - headerHeight > 80) {
              window.scrollTo({
                  top: sectionTop - headerHeight,
                  behavior: 'smooth', // Use smooth for better UX
              });
              context.setState({patch: '/', class: null})
              // Log to verify the scroll position
          }
        }, 200); // Adjust debounce timeout as needed
      };
      goToSection();
    }, [context.state]); // Add context.state.section as a dependency

    return null
};

export default UseGoToSection;
