import React, { useContext } from 'react';
import '../style/services.sass';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';
import { GlobalContext } from '../context/GlobalContext';
import useWindowSize from '../hooks/useWindowSize';

export default function ProductHighlights({productFeatures}) {
    const sectionImage1 = getImage(productFeatures.contentSection.image1);
    const sectionImage2 = getImage(productFeatures.contentSection.image2);
    const context = useContext(GlobalContext);
    const { width } = useWindowSize()
    const handleClick = async (e, newPath, newClass) => {
        await context.setState({ path: newPath, section: newClass });
    }
  return (
    <section className='section2'>
        <div>
            <div className='product-features-section-1' >
                <div className="products-c2-container" style={{display: 'flex' , justifyContent: 'space-evenly' , marginTop: width > 768 ? '7%' : '20%', width:'80%'}}>
                    <div className="products-features-sub-2" style={{display:'flex' , flexDirection: 'column' , justifyContent: 'flex-start' , alignItems: 'center'}}>
                        <h3 className="products-c2-sub-2-h" style={{width: width > 768 ? '75%' : '100%' , marginRight: width > 768 ? '5%' : '0%' }}>
                        {productFeatures.contentSection.title.map((part, index) => (
                            <span style={{ color: part.color }}>{part.fragment + " "}</span>
                        ))}
                        </h3>
                        <p className="products-c2-sub-2-p1" style={{width: width > 768 ? '80%' : '100%'}}>{productFeatures.contentSection.description}</p>
                        <button className="products-c2-sub-2-button" onClick={(e) => handleClick(e, "/contact", "section2")} style={{alignSelf: width > 768 ? 'start' : 'center' , marginLeft: width > 768 ? '10%' : '0%'}}><Link to="/contact" style={{color: "white"}}>CONTACT US</Link></button>
                    </div>
                    <div style={{width: width > 768 ? '50%' : '100%'}}>
                        <GatsbyImage image={sectionImage1} alt='Image' style={{width: width > 768 ? '90%' : '100%'}}/>
                    </div>
                </div>
                <div className='product-features-section-1-image'>
                    <GatsbyImage image={sectionImage2} alt='Image' className='image'/>
                </div>
            </div>
            <div className="product-features-content-section section3">
                <div className="product-features-section-title">
                    <h2 className="solutions-header">
                    {productFeatures.title.map((part, index) => (
                        <span style={{ color: part.color }}>{part.fragment + " "}</span>
                    ))}
                    </h2>
                </div>
                <div className="product-features-content">
                    {productFeatures.productFeaturesList.map((feature, index) => {
                        const image = getImage(feature.icon);
                        return (
                            <div key={index} className="product-feature">
                                {width > 768 && <div className="product-feature-icon">
                                    {image ? (
                                    <GatsbyImage image={image} alt='Feature' className='product-feature-icon-image' />
                                    ) : (
                                    <img src={feature.icon} alt='Feature' className='product-feature-icon-image' />
                                    )}
                                </div>}
                                <div className="product-feature-container">
                                    {width <= 768 && <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                                        <div className="product-feature-icon">
                                        {image ? (
                                        <GatsbyImage image={image} alt='Feature' className='product-feature-icon-image' />
                                        ) : (
                                        <img src={feature.icon} alt='Feature' className='product-feature-icon-image' />
                                        )}
                                        </div>
                                        <div className="product-feature-title">{feature.title}</div>
                                    </div>
                                    }
                                    {width > 768 && <div className="product-feature-title">{feature.title}</div>}
                                    <div className="product-feature-description">{feature.description}</div>
                                </div>
                            </div>
                        )}
                    )}
                </div>
            </div>
        </div>
    </section>
  )
}